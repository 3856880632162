//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import kaptcha from "@/lib/data-service/default/web_common_kaptcha";
import phoneRegister from "@/lib/data-service/default/web_common_register";
import personageDetailByBuyerId from "@/lib/data-service/default/web_personage_personageDetailByBuyerId";
import personageSet from "@/lib/data-service/default/web_personage_personageSet";
import phoneCodeCheck from "@/lib/data-service/default/web_common_phoneCodeCheck";

export default {
  name: "personage-info",
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      if (!Number.isInteger(+value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (phoneReg.test(value)) {
          callback();
        } else {
          callback(new Error("电话号码格式不正确"));
        }
      }
    };
    var isEmail = (rule, value, callback) => {
      const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      if (!value) {
        return callback(new Error("请输入邮箱"));
      } else if (!reg.test(value)) {
        callback(new Error("输入邮箱格式不正确！"));
      } else {
        callback();
      }
    };
    return {
      fromData: {
        phone: ""
      },
      loading: true,
      email: {
        show: false,
        value: ""
      },
      codeInfo: {
        phone: "",
        code: ""
      },
      dialogShow: {
        show: false,
        show1: true,
        show3: false
      },
      authCode: {
        url: "",
        value: "",
        show: false
      },
      rules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        phone: [{ validator: checkPhone, trigger: "blur" }],
        value: [{ validator: isEmail, trigger: "blur" }]
      }
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getKaptcha() {
      this.dialogShow.show3 = true;
      this.authCode.value = "";
      kaptcha().then(res => {
        this.authCode.url = res.img;
        this.authCode.uuid = res.uuid;
        this.$refs.authCode.focus();
      });
    },
    getEmail() {
      this.$refs["email"].validate(valid => {
        if (valid) {
          //   this.fromData.eMail = this.email.value;
          personageSet({
            eMail: this.email.value,
            phone: this.fromData.phone
          }).then(res => {
            console.log(res);
            this.email.show = false;
            this.getUserInfo();
          });
        } else {
          return false;
        }
      });
    },
    getUserInfo() {
      personageDetailByBuyerId().then(res => {
        this.loading = false;
        this.fromData = res.personageDetailResult;
      });
    },
    getCode() {
      if (!this.authCode.value)
        return this.$message({ type: "warning", message: "请输入短信验证码" });
      let data = {
        phoneNumber: !this.dialogShow.show1
          ? this.codeInfo.phone
          : this.fromData.phone,
        verificationCode: this.authCode.value,
        roleType: 2,
        uuid: this.authCode.uuid
      };
      phoneRegister(data).then(
        res => {
          this.$message({ type: "success", message: "发送成功，请稍等！" });
        },
        error => {}
      );
      this.dialogShow.show3 = false;
    },
    next() {
      if (!this.dialogShow.show1) {
        this.$refs["codeInfo"].validate(valid => {
          if (valid) {
            if (this.fromData.phone == this.codeInfo.phone)
              return this.$message({
                type: "warning",
                message: "修改手机号码不能和原手机号一致！"
              });
            let data = {
              smsCode: this.codeInfo.code,
              phone: this.codeInfo.phone
            };
            phoneCodeCheck(data).then(res => {
              personageSet({
                phone: this.codeInfo.phone,
                eMail: this.fromData.eMail
              }).then(res => {
                console.log(res);
                this.dialogShow.show = false;
                this.getUserInfo();
              });
            });
          } else {
            return false;
          }
        });
      } else {
        let data = {
          smsCode: this.codeInfo.code,
          phone: this.fromData.phone
        };
        this.$refs["codeInfo"].validateField("code");
        if (!this.codeInfo.code) return;
        phoneCodeCheck(data).then(res => {
          this.dialogShow.show1 = false;
          this.codeInfo.code = "";
        });
      }
    },
    reset() {
      this.dialogShow = {
        show: true,
        show1: true,
        show3: false
      };
      this.$nextTick(() => {
        this.$refs["codeInfo"].resetFields();
      });
    }
  }
};
